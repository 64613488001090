<template>
  <div class="vip-container">
    <div class="export-container">
<!--      <el-button type="success" :disabled="vips.length === 0" @click="downloadReports">导出EXCEL</el-button>-->
    </div>
    <div class="table-container">
      <el-table :data="orders"
                border
                style="width: 100%"
                max-height="780px"
                row-key="id"
                size="mini"
                v-loading="isLoading"
      >
        <el-table-column align="center" prop="id" label="序号" />
        <el-table-column align="center" prop="date" label="日期" />
        <el-table-column align="center" prop="tick_type" label="场次"/>
        <el-table-column align="center" prop="real_name" label="姓名" />
        <el-table-column align="center" prop="card_type" label="证件类型" />
        <el-table-column align="center" prop="card_code" label="证件编码" />
        <el-table-column align="center" prop="status" label="状态" />
      </el-table>
      <div class="page-container">
        <el-pagination
            @size-change="getVips"
            @current-change="getVips"
            :current-page.sync="curPage"
            :page-size.sync="pageSize"
            :page-sizes="[20, 100, 200, 300, 400]"
            layout="total, sizes, prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api"
export default {
  name: 'vipUser',
  data() {
    return {
      orders: [],
      curPage: 1,
      pageSize: 20,
      total: 100,
      isLoading: false,

    }
  },
  methods: {
    async getVips() {
      const result = await api.getVips(this.curPage, this.pageSize)
      this.orders = result.data.records.map(record => ({
        ...record,
      }))
      this.total = result.data.total
    },
  },
  async mounted() {
    await this.getVips()
  }
}
</script>
<style scoped>
.vip-container {
  position: relative;
}
.export-container {
  display: flex;
  flex-direction: row-reverse;
}
.table-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 10px;
}
.page-container {
  display: flex;
  margin-top: 16px;
  flex-direction: row-reverse;
}
</style>
